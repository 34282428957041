import * as React from "react";
import profileDefault from "../images/profileDefault.png";

const Header = () => {
  return (
    <div className="h-16 bg-theme-gray flex justify-end">
      <div className="w-80 pr-3 flex justify-end items-center">
        <div className="m-2 text-lg text-white">Tester Tester - 123456</div>
        <img
          className="h-12 w-12 rounded-3xl m-2"
          src={profileDefault}
          alt=""
        />
      </div>
    </div>
  );
};
export default Header;
