import * as React from "react";
import { Link } from "gatsby";
import { useState } from "react";
import whiteTriangle from "../images/whiteTriangle.png";

const Navigation = () => {
  const [extraTab, setExtraTab] = useState(true);
  const [rotateAngle, setRotateAngle] = useState(0);
  const rotateIcon = () => {
    rotateAngle === 0 ? setRotateAngle(90) : setRotateAngle(0);
  };
  return (
    <div
      className="w-48 shrink-0 min-h-[calc(100vh-4rem)] bg-theme-gray text-white text-sm 
      font-medium border-t border-neutral-500 [&>div]:py-2 [&>div]:px-3"
    >
      <div
        className={
          typeof window !== `undefined` &&
          window.location.pathname === "/dashboard"
            ? "bg-theme-red"
            : "bg-theme-gray"
        }
      >
        <Link
          className="nav-icon bg-[url('../images/whiteDashboard.png')]"
          to="/dashboard"
        >
          Dashboard
        </Link>
      </div>
      <article
        className={`py-2 pl-3 ${
          typeof window !== `undefined` &&
          window.location.pathname.includes("/property")
            ? "bg-theme-red"
            : "bg-theme-gray"
        }`}
      >
        <Link
          className="inline-block nav-icon bg-[url('../images/whiteHouse.png')]"
          to="/property/stock"
        >
          Housing Stock
        </Link>
        <img
          className="h-4 w-4 inline-block cursor-pointer"
          src={whiteTriangle}
          alt=""
          style={{ transform: `rotate(${rotateAngle}deg)` }}
          onClick={() => {
            rotateIcon();
            setExtraTab(!extraTab);
          }}
        />
      </article>
      {extraTab && (
        <article className="[&>*]:pl-20 [&>*]:text-sm [&>*]:bg-theme-dark">
          <Link to="/property/stock">Sell</Link>
          <Link to="/property/available">Lease</Link>
        </article>
      )}
      <div>
        <Link
          className="nav-icon bg-[url('../images/whiteMessage.png')]"
          to="/"
        >
          Message List
        </Link>
      </div>
      <div>
        <Link className="nav-icon bg-[url('../images/whitePeople.png')]" to="/">
          Customer
        </Link>
      </div>
      <div>
        <Link className="nav-icon bg-[url('../images/whiteHouse.png')]" to="/">
          Invest
        </Link>
      </div>
      <div>
        <Link className="nav-icon bg-[url('../images/whiteHouse.png')]" to="/">
          Projects
        </Link>
      </div>
      <div>
        <Link className="nav-icon bg-[url('../images/whiteHouse.png')]" to="/">
          Media
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
