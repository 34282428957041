import * as React from "react";
import Layout from "../components/layout";

const DashboardPage = () => {
  return (
    <Layout>
      <h6>
        Current Location: 
        <span className="font-semibold"> Dashboard</span>
      </h6>
    </Layout>
  );
};

export default DashboardPage;

export const Head = () => <title>Real estate | Dashboard</title>;
